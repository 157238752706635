import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StatusTag, { StatusVariant } from '../../../components/shared/tags/StatusTag';
import { DataJobStatus } from '../../../models/DataImport';

export const dataJobStatusKeys = {
  [DataJobStatus.NotStarted]: 'common:data-job-status.not-started',
  [DataJobStatus.Setup]: 'common:data-job-status.setup',
  [DataJobStatus.InProgress]: 'common:data-job-status.in-progress',
  [DataJobStatus.Completed]: 'common:data-job-status.completed',
  [DataJobStatus.Failed]: 'common:data-job-status.failed',
  [DataJobStatus.Cancelled]: 'common:data-job-status.cancelled',
} as const;

const variant = {
  [DataJobStatus.NotStarted]: StatusVariant.RED,
  [DataJobStatus.Setup]: StatusVariant.GRAY,
  [DataJobStatus.InProgress]: StatusVariant.GRAY,
  [DataJobStatus.Completed]: StatusVariant.GREEN,
  [DataJobStatus.Failed]: StatusVariant.RED,
  [DataJobStatus.Cancelled]: StatusVariant.GRAY,
};

type DataJobStatusKey = keyof typeof dataJobStatusKeys;

export const DataJobStatusTag: FC<{ status: DataJobStatusKey }> = ({ status }) => {
  const { t } = useTranslation(['common']);
  return <StatusTag data-cy="data-job-status-tag" text={t(dataJobStatusKeys[status])} statusType={variant[status]} />;
};
